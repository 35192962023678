@import url(https://fonts.googleapis.com/css2?family=Yellowtail&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap);
body {
  margin: 0px !important;
  font-family: Raleway, sans-serif;
  background: #fbfbfb;
}
/* 
::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}
.MuiStepIcon-text {
  fill: black !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: white !important;
}
.MuiStepIcon-root {
  color: rgba(255, 255, 255, 0.38) !important;
}
.MuiStepLabel-labelContainer .MuiTypography-root {
  color: white !important;
}
.MuiStepLabel-label {
  color: rgba(255, 255, 255, 0.54);
}
.MuiTypography-body1 {
  font-weight: 600 !important;
}
.h_iframe iframe {
  width: 100%;
  height: 500px;
  overflow: hidden;
}
.h_iframe {
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.f_iframe iframe {
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
}
.f_iframe {
  /* height: 100%; */
  width: 100%;
  overflow: hidden;
}

.MuiPickersToolbar-toolbar {
  background-color: #cf362a !important;
}

.MuiPickersDay-daySelected {
  background-color: #cf362a !important;
}

.MuiDropzonePreviewList-image {
  height: 55px !important;
}

.MuiDropzoneArea-root {
  min-height: 180px !important;
}

.MuiTypography-h5 {
  font-size: 14px !important;
}

.MuiDropzonePreviewList-image {
  height: 55px !important;
}

.MuiDropzoneArea-root {
  min-height: 180px !important;
}

.MuiTypography-h5 {
  font-size: 14px !important;
}

.MuiDropzonePreviewList-image {
  height: 55px !important;
}

.MuiDropzoneArea-root {
  min-height: 180px !important;
}

.MuiTypography-h5 {
  font-size: 14px !important;
}

.custom-button {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    border: none;
    /* Default border style */
    cursor: pointer;
    /* Ensure cursor pointer is applied */
    outline: none;
}

.custom-button.primary {
    color: #1976d2;
    /* Primary color */
}

.custom-button.secondary {
    color: #f50057;
    /* Secondary color */
}

.custom-button.contained {
    background-color: #1976d2;
    /* Primary color */
    color: #fff;
}

.custom-button.contained.secondary {
    background-color: #f50057;
    /* Secondary color */
    color: #fff;
}

.custom-button.outlined {
    background-color: transparent;
    border: 2px solid currentColor;
    /* Border color based on text color */
}

.custom-button.outlined.secondary {
    color: #f50057;
    /* Secondary color */
}

.custom-button.textOnly {
    background-color: transparent;
    border: none;
    /* Remove border for text variant */
}

.custom-button.textOnly.primary {
    color: #1976d2;
    /* Primary color */
}

.custom-button.textOnly.secondary {
    color: #f50057;
    /* Secondary color */
}

.custom-button:hover {
    opacity: 0.9;
}

.custom-button:disabled {
    background-color: #e0e0e0;
    color: #bdbdbd;
    cursor: not-allowed;
}

@import url("https://fonts.googleapis.com/css2?family=Yellowtail&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap");
body {
  margin: 0px !important;
  font-family: Raleway, sans-serif;
  background: #fbfbfb;
}
/* 
::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}
.MuiStepIcon-text {
  fill: black !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: white !important;
}
.MuiStepIcon-root {
  color: rgba(255, 255, 255, 0.38) !important;
}
.MuiStepLabel-labelContainer .MuiTypography-root {
  color: white !important;
}
.MuiStepLabel-label {
  color: rgba(255, 255, 255, 0.54);
}
.MuiTypography-body1 {
  font-weight: 600 !important;
}
.h_iframe iframe {
  width: 100%;
  height: 500px;
  overflow: hidden;
}
.h_iframe {
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.f_iframe iframe {
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
}
.f_iframe {
  /* height: 100%; */
  width: 100%;
  overflow: hidden;
}

.MuiPickersToolbar-toolbar {
  background-color: #cf362a !important;
}

.MuiPickersDay-daySelected {
  background-color: #cf362a !important;
}

.custom-button {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    border: none;
    /* Default border style */
    cursor: pointer;
    /* Ensure cursor pointer is applied */
    outline: none;
}

.custom-button.primary {
    color: #1976d2;
    /* Primary color */
}

.custom-button.secondary {
    color: #f50057;
    /* Secondary color */
}

.custom-button.contained {
    background-color: #1976d2;
    /* Primary color */
    color: #fff;
}

.custom-button.contained.secondary {
    background-color: #f50057;
    /* Secondary color */
    color: #fff;
}

.custom-button.outlined {
    background-color: transparent;
    border: 2px solid currentColor;
    /* Border color based on text color */
}

.custom-button.outlined.secondary {
    color: #f50057;
    /* Secondary color */
}

.custom-button.textOnly {
    background-color: transparent;
    border: none;
    /* Remove border for text variant */
}

.custom-button.textOnly.primary {
    color: #1976d2;
    /* Primary color */
}

.custom-button.textOnly.secondary {
    color: #f50057;
    /* Secondary color */
}

.custom-button:hover {
    opacity: 0.9;
}

.custom-button:disabled {
    background-color: #e0e0e0;
    color: #bdbdbd;
    cursor: not-allowed;
}
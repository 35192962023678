.MuiDropzonePreviewList-image {
  height: 55px !important;
}

.MuiDropzoneArea-root {
  min-height: 180px !important;
}

.MuiTypography-h5 {
  font-size: 14px !important;
}
